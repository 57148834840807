@font-face {
  font-family: "Regular";
  src: url(../fonts/Roboto-Regular.ttf);
}
@font-face {
  font-family: "Medium";
  src: url(../fonts/Roboto-Medium.ttf);
}
@font-face {
  font-family: "Bold";
  src: url(../fonts/Roboto-Bold.ttf);
}
@font-face {
  font-family: "Trafaret";
  src: url(../fonts/Trafaret\ Sherbackoffalex\ +\ Lat.ttf);
}
:root {
  --main-color: #ff5200;
  --dark-color: #111111;
  --white-color: #fefff8;
  --secundary-white: #fefff8da;
  --medium-font: "Medium";
  --bold-font: "Bold";
  --border-color-default: #27282b;
  --default-transition: all 0.3s ease-in-out;
}
html {
  font-size: 10px;
}
body,
html {
  scroll-behavior: smooth;
  font-family: "Regular";
  background-color: var(--dark-color);
  color: var(--white-color);
}
header {
  display: flex;
  justify-content: center;
  padding: 2rem;
  box-sizing: border-box;
  width: 100%;
  border-bottom: 0.1rem solid var(--border-color-default);
}
header nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
}
header nav a.logo {
  font: 4rem "Trafaret";
  color: var(--white-color);
  text-transform: uppercase;
  width: 30%;
}
header nav ul {
  display: flex;
  align-items: center;
  width: 37%;
}
header nav ul li {
  margin-right: 3rem;
}
header nav ul li a {
  color: var(--white-color);
  font-size: 1.4rem;
  position: relative;
  padding-bottom: 0.2rem;
  transition: all 0.3s ease-in-out;
  text-wrap: nowrap;
}
header nav ul li a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 0.15rem;
  width: 0;
  background-color: var(--main-color);
  border-radius: 2rem;
  transition: all 0.3s ease-in-out;
}
header nav ul li a:hover {
  color: var(--main-color);
}
header nav ul li a:hover::after {
  width: 100%;
}
header nav ul li a.active {
  color: var(--main-color);
}
header nav ul li a.active::after {
  width: 100%;
}
header nav ul li:last-child {
  margin-right: 0;
}
header .container-request {
  display: flex;
  justify-content: flex-end;
  width: 30%;
}
header .container-request a {
  color: var(--white-color);
  padding: 1rem 1.5rem;
  box-sizing: border-box;
  font-size: 1.4rem;
  border: 0.15rem solid var(--border-color-default);
  border-radius: 10rem;
  transition: all 0.3s linear;
}
header .container-request a:hover {
  border-color: var(--main-color);
}
footer {
  padding: 2rem 0;
  box-sizing: border-box;
  border-top: 0.1rem solid var(--border-color-default);
}
footer .container {
  display: flex;
  width: 90%;
  margin: 0 auto;
}
footer .container .block {
  width: 24%;
  margin-right: 1.33%;
}
footer .container .block h3 {
  font: 1.6rem "Medium";
  margin-bottom: 1rem;
}
footer .container .block a.logo {
  font: 4rem "Trafaret";
  color: var(--white-color);
  text-transform: uppercase;
  width: 30%;
}
footer .container .block .container-request {
  display: flex;
  width: 100%;
  margin-top: 2rem;
}
footer .container .block .container-request a {
  color: var(--white-color);
  padding: 1rem 1.5rem;
  box-sizing: border-box;
  font-size: 1.4rem;
  border: 0.15rem solid var(--border-color-default);
  border-radius: 10rem;
  transition: all 0.3s linear;
}
footer .container .block .container-request a:hover {
  border-color: var(--main-color);
}
footer .container .block p {
  margin-top: 1rem;
  font-size: 1.4rem;
}
footer .container .block ul li {
  margin-bottom: 1rem;
}
footer .container .block ul li a {
  color: var(--secundary-white);
  font-size: 1.4rem;
  position: relative;
  padding-bottom: 0.2rem;
  transition: all 0.3s ease-in-out;
  text-wrap: nowrap;
}
footer .container .block ul li a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 0.15rem;
  width: 0;
  background-color: var(--main-color);
  border-radius: 2rem;
  transition: all 0.3s ease-in-out;
}
footer .container .block ul li a:hover {
  color: var(--main-color);
}
footer .container .block ul li a:hover::after {
  width: 100%;
}
footer .container .block ul li a.active {
  color: var(--main-color);
}
footer .container .block ul li a.active::after {
  width: 100%;
}
.yellow {
  color: var(--main-color);
}
.bold {
  font-family: "Medium";
}
main {
  width: 90%;
  margin: 2rem auto;
}
::-webkit-scrollbar {
  position: absolute;
  left: 50%;
  justify-content: center;
  width: 5px;
  max-height: 50px;
  border-radius: 12px;
}
::-webkit-scrollbar-track {
  background: var(--primary-shadow);
}
::-webkit-scrollbar-thumb {
  background-color: var(--main-color);
  border-radius: 200px;
  box-shadow: var(--primary-shadow);
  width: 10px;
}
.mobile {
  display: none;
}
.mobileHeader {
  display: none;
}
@media screen and (max-width: 768px) {
  .no-scroll {
    height: 100vh;
    overflow: hidden;
  }
  header {
    display: flex;
    justify-content: center;
    padding: 2rem;
    box-sizing: border-box;
    width: 100%;
    border-bottom: 0.1rem solid var(--border-color-default);
  }
  header nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
  }
  header nav a.logo {
    font: 4rem "Trafaret";
    color: var(--white-color);
    text-transform: uppercase;
    width: unset;
  }
  header nav ul {
    display: flex;
    align-items: center;
    width: unset;
  }
  header nav ul li {
    display: none;
  }
  header nav ul li.mobile {
    display: flex;
    flex-direction: column;
  }
  header nav ul li.mobile button {
    width: 3rem;
    height: 0.2rem;
    background-color: var(--white-color);
    margin-bottom: 0.7rem;
    border-radius: 0.8rem;
  }
  header nav ul li.mobile button:nth-child(3) {
    margin-bottom: 0;
  }
  header .container-request {
    display: none;
  }
  .mobileHeader {
    display: unset;
    position: fixed;
    top: 0;
    height: 100%;
    transition: var(--default-transition);
    width: 0;
    overflow: hidden;
    left: 0;
    z-index: 9999;
    background-color: var(--dark-color);
    border-right: 0.1rem solid var(--border-color-default);
  }
  .mobileHeader .textPart {
    padding: 2rem;
    box-sizing: border-box;
  }
  .mobileHeader .textPart a.logo {
    display: flex;
    font: 4rem "Trafaret";
    color: var(--white-color);
    text-transform: uppercase;
    width: 100%;
    padding-bottom: 2rem;
    border-bottom: 0.1rem solid var(--border-color-default);
  }
  .mobileHeader .textPart ul {
    margin-top: 3rem;
  }
  .mobileHeader .textPart ul li {
    margin-bottom: 2rem;
  }
  .mobileHeader .textPart ul li a {
    color: var(--white-color);
    font-size: 1.8rem;
    position: relative;
    padding-bottom: 0.2rem;
    transition: all 0.3s ease-in-out;
    text-wrap: nowrap;
  }
  .mobileHeader .textPart ul li a::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 0.15rem;
    width: 0;
    background-color: var(--main-color);
    border-radius: 2rem;
    transition: all 0.3s ease-in-out;
  }
  .mobileHeader .textPart ul li a:hover {
    color: var(--main-color);
  }
  .mobileHeader .textPart ul li a:hover::after {
    width: 100%;
  }
  .mobileHeader .textPart ul li a.active {
    color: var(--main-color);
  }
  .mobileHeader .textPart ul li a.active::after {
    width: 100%;
  }
  .mobileHeader .textPart ul li:last-child {
    margin-right: 0;
  }
  .mobileHeader .textPart .container-request {
    display: flex;
    width: 100%;
  }
  .mobileHeader .textPart .container-request a {
    color: var(--white-color);
    padding: 1.5rem;
    box-sizing: border-box;
    font-size: 1.4rem;
    width: 100%;
    text-align: center;
    border: 0.15rem solid var(--border-color-default);
    border-radius: 10rem;
    transition: all 0.3s linear;
  }
  .mobileHeader .textPart .container-request a:hover {
    border-color: var(--main-color);
  }
  .activeMobileHeader {
    width: 80%;
  }
  main {
    width: 90%;
    margin: 2rem auto;
  }
  footer {
    padding: 2rem 0;
    box-sizing: border-box;
    border-top: 0.1rem solid var(--border-color-default);
  }
  footer .container {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
  }
  footer .container .block {
    width: 100%;
    margin-right: 0;
    margin-bottom: 2rem;
  }
  footer .container .block h3 {
    font: 1.6rem "Medium";
    margin-bottom: 1rem;
  }
  footer .container .block a.logo {
    font: 4rem "Trafaret";
    color: var(--white-color);
    text-transform: uppercase;
    width: 30%;
  }
  footer .container .block .container-request {
    display: flex;
    width: 100%;
    margin-top: 2rem;
  }
  footer .container .block .container-request a {
    color: var(--white-color);
    padding: 1rem 1.5rem;
    box-sizing: border-box;
    font-size: 1.4rem;
    border: 0.15rem solid var(--border-color-default);
    border-radius: 10rem;
    transition: all 0.3s linear;
  }
  footer .container .block .container-request a:hover {
    border-color: var(--main-color);
  }
  footer .container .block p {
    margin-top: 1rem;
    font-size: 1.4rem;
  }
  footer .container .block ul li {
    margin-bottom: 1rem;
  }
  footer .container .block ul li a {
    color: var(--secundary-white);
    font-size: 1.4rem;
    position: relative;
    padding-bottom: 0.2rem;
    transition: all 0.3s ease-in-out;
    text-wrap: nowrap;
  }
  footer .container .block ul li a::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 0.15rem;
    width: 0;
    background-color: var(--main-color);
    border-radius: 2rem;
    transition: all 0.3s ease-in-out;
  }
  footer .container .block ul li a:hover {
    color: var(--main-color);
  }
  footer .container .block ul li a:hover::after {
    width: 100%;
  }
  footer .container .block ul li a.active {
    color: var(--main-color);
  }
  footer .container .block ul li a.active::after {
    width: 100%;
  }
}
