main.delivery h1 {
  font: 2.4rem "Medium";
  width: 60vw;
  margin: 0 auto;
}
main.delivery .content {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  padding: 1.5rem;
  box-sizing: border-box;
  border: 0.1rem solid var(--border-color-default);
  border-radius: 0.8rem;
  margin-bottom: 2rem;
}
main.delivery .content h3 {
  font-size: 2rem;
  margin-bottom: 2rem;
}
main.delivery .content ul {
  list-style: disc;
  margin: 0;
  margin-left: 2rem;
}
main.delivery .content ul li {
  margin-bottom: 1rem;
}
main.delivery .content ul li a {
  color: var(--secundary-white);
  font-size: 1.8rem;
  border-bottom: 0.1rem solid var(--main-color);
}
main.delivery .content ul li::marker {
  color: var(--main-color);
}
main.delivery .contentWrapper {
  width: 60vw;
  margin: 0 auto;
}
