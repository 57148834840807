main.home {
  width: 100%;
  margin-top: 0;
}
main.home .firstScreen {
  display: flex;
  justify-content: center;
  min-height: 70rem;
  width: 100%;
  background-image: url(../../../img/homePage/firstScreen.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0 48%;
  padding: 2rem;
  box-sizing: border-box;
  position: relative;
}
main.home .firstScreen .left {
  width: 90%;
  position: relative;
  z-index: 2;
  margin-top: 5rem;
}
main.home .firstScreen .left h1 {
  font: 3.2rem var(--medium-font);
  margin-bottom: 1rem;
}
main.home .firstScreen .left p {
  font-size: 1.8rem;
  width: 50%;
  color: var(--secundary-white);
  line-height: 150%;
}
main.home .firstScreen .blurLayer {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: var(--default-transition);
}
main.home .firstScreen .buttons-container {
  display: flex;
  margin-top: 2rem;
}
main.home .firstScreen .buttons-container a {
  padding: 1rem 1.5rem;
  box-sizing: border-box;
  color: var(--secundary-white);
  font-size: 1.6rem;
  margin-right: 2rem;
  border: 0.1rem solid var(--secundary-white);
  border-radius: 0.5rem;
  transition: var(--default-transition);
}
main.home .firstScreen .buttons-container a:hover {
  background-color: var(--secundary-white);
  color: var(--dark-color);
  border-color: transparent;
}
main.home .firstScreen .buttons-container a.catalogLink {
  background-color: var(--secundary-white);
  color: var(--dark-color);
  border-color: transparent;
}
main.home .secondSection {
  width: 90%;
  margin: 0 auto;
  margin-top: 6rem;
}
main.home .secondSection h2 {
  font: 2.4rem "Medium";
  margin: 2rem auto;
}
main.home .secondSection .row {
  display: flex;
  width: 100%;
}
main.home .secondSection .row .block {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  min-height: 40rem;
  width: 25%;
  overflow: hidden;
  position: relative;
}
main.home .secondSection .row .block img {
  position: absolute;
  width: 100%;
  z-index: 1;
  height: 100%;
  object-fit: cover;
  transition: var(--default-transition);
}
main.home .secondSection .row .block p {
  position: relative;
  z-index: 2;
  font: 2.4rem "Bold";
  text-transform: uppercase;
  margin-top: 8rem;
}
main.home .secondSection .row .block:hover img {
  transform: scale(1.1);
}
main.home .secondSection .row .block:nth-child(1) {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}
main.home .secondSection .row .block:nth-child(4) {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
main.home .ReasonsToWork {
  width: 90%;
  margin: 0 auto;
  margin-top: 6rem;
}
main.home .ReasonsToWork h2 {
  font: 2.4rem "Medium";
  margin: 2rem auto;
}
main.home .ReasonsToWork .row {
  display: flex;
  justify-content: space-between;
}
main.home .ReasonsToWork .row .block {
  width: 24%;
  border: 0.1rem solid var(--border-color-default);
  padding: 1rem 1.5rem;
  box-sizing: border-box;
  border-radius: 0.8rem;
}
main.home .ReasonsToWork .row .block h3 {
  font: 2rem "Medium";
}
main.home .ReasonsToWork .row .block .inRow {
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 0.2rem solid var(--main-color);
}
main.home .ReasonsToWork .row .block .inRow img {
  width: 2.5rem;
  object-fit: contain;
  margin-right: 2rem;
}
main.home .ReasonsToWork .row .block p {
  font-size: 1.4rem;
  color: var(--secundary-white);
  line-height: 130%;
}
main.home .catalog {
  width: 90%;
  margin: 0 auto;
  margin-top: 6rem;
}
main.home .catalog h2 {
  font: 2.4rem "Medium";
  margin: 2rem auto;
}
main.home .catalog .row {
  display: flex;
  flex-wrap: wrap;
}
main.home .catalog .row a.block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  width: 32%;
  margin-right: 1.33%;
  margin-bottom: 1.33%;
  min-height: 35rem;
  border-radius: 1rem;
  overflow: hidden;
  position: relative;
}
main.home .catalog .row a.block img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}
main.home .catalog .row a.block .blurLayer {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: var(--default-transition);
}
main.home .catalog .row a.block p {
  position: relative;
  z-index: 3;
  margin: 2rem;
  color: var(--white-color);
  font-size: 2rem;
}
main.home .catalog .row a.block a.go {
  z-index: 3;
  margin: 2rem;
  color: var(--secundary-white);
  border: 0.1rem solid var(--secundary-white);
  font-size: 1.4rem;
  padding: 1rem;
  width: 40%;
  text-align: center;
  border-radius: 0.8rem;
  transition: var(--default-transition);
}
main.home .catalog .row a.block a.go:hover {
  background-color: var(--secundary-white);
  color: var(--dark-color);
  border-color: transparent;
}
main.home .catalog .row a.block:hover .blurLayer {
  backdrop-filter: blur(5px);
}
@media screen and (max-width: 768px) {
  main.home .firstScreen {
    background-image: url(../../../img/homePage/firstScreen_mobile.png);
    background-position: unset;
    min-height: 50rem;
    background-size: contain;
  }
  main.home .firstScreen .left {
    width: 90%;
    position: relative;
    z-index: 2;
    margin-top: 3rem;
  }
  main.home .firstScreen .left h1 {
    font: 2.4rem var(--medium-font);
    margin-bottom: 1rem;
  }
  main.home .firstScreen .left p {
    font-size: 1.6rem;
    width: 100%;
    color: var(--secundary-white);
    line-height: 150%;
  }
  main.home .firstScreen .blurLayer {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    transition: var(--default-transition);
  }
  main.home .firstScreen .buttons-container {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
  }
  main.home .firstScreen .buttons-container a {
    padding: 1rem 1.5rem;
    box-sizing: border-box;
    color: var(--secundary-white);
    font-size: 1.6rem;
    margin-right: 0;
    margin-bottom: 2rem;
    text-align: center;
    border: 0.1rem solid var(--secundary-white);
    border-radius: 0.5rem;
    transition: var(--default-transition);
  }
  main.home .firstScreen .buttons-container a:hover {
    background-color: var(--secundary-white);
    color: var(--dark-color);
    border-color: transparent;
  }
  main.home .secondSection {
    width: 90%;
    margin: 0 auto;
    margin-top: 6rem;
  }
  main.home .secondSection h2 {
    font: 2rem "Medium";
    margin: 2rem auto;
  }
  main.home .secondSection .row {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  main.home .secondSection .row .block {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40rem;
    width: 100%;
    border-radius: 0.8rem;
    overflow: hidden;
    margin-bottom: 1rem;
    position: relative;
  }
  main.home .secondSection .row .block img {
    position: absolute;
    width: 100%;
    z-index: 1;
    height: 100%;
    object-fit: cover;
    transition: var(--default-transition);
  }
  main.home .secondSection .row .block p {
    position: relative;
    z-index: 2;
    font: 2rem "Bold";
    text-transform: uppercase;
    margin-top: 0;
  }
  main.home .secondSection .row .block:hover img {
    transform: scale(1.1);
  }
  main.home .secondSection .row .block:nth-child(1) {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  main.home .secondSection .row .block:nth-child(4) {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
  main.home .ReasonsToWork {
    width: 90%;
    margin: 0 auto;
    margin-top: 6rem;
  }
  main.home .ReasonsToWork h2 {
    font: 2rem "Medium";
    margin: 2rem auto;
  }
  main.home .ReasonsToWork .row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  main.home .ReasonsToWork .row .block {
    width: 100%;
    margin-bottom: 1rem;
    border: 0.1rem solid var(--border-color-default);
    padding: 1rem 1.5rem;
    box-sizing: border-box;
    border-radius: 0.8rem;
  }
  main.home .ReasonsToWork .row .block h3 {
    font: 1.8rem "Medium";
  }
  main.home .ReasonsToWork .row .block .inRow {
    display: flex;
    align-items: center;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 0.2rem solid var(--main-color);
  }
  main.home .ReasonsToWork .row .block .inRow img {
    width: 2.5rem;
    object-fit: contain;
    margin-right: 2rem;
  }
  main.home .ReasonsToWork .row .block p {
    font-size: 1.4rem;
    color: var(--secundary-white);
    line-height: 130%;
  }
  main.home .catalog {
    width: 90%;
    margin: 0 auto;
    margin-top: 6rem;
  }
  main.home .catalog h2 {
    font: 2rem "Medium";
    margin: 2rem auto;
  }
  main.home .catalog .row {
    display: flex;
    flex-wrap: wrap;
  }
  main.home .catalog .row a.block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    width: 100%;
    margin-right: 0;
    margin-bottom: 2rem;
    min-height: 35rem;
    border-radius: 1rem;
    overflow: hidden;
    position: relative;
  }
  main.home .catalog .row a.block img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
  main.home .catalog .row a.block .blurLayer {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    transition: var(--default-transition);
  }
  main.home .catalog .row a.block p {
    position: relative;
    z-index: 3;
    margin: 2rem;
    color: var(--white-color);
    font-size: 2rem;
  }
  main.home .catalog .row a.block a.go {
    z-index: 3;
    margin: 2rem;
    color: var(--secundary-white);
    border: 0.1rem solid var(--secundary-white);
    font-size: 1.4rem;
    padding: 1rem;
    width: 40%;
    text-align: center;
    border-radius: 0.8rem;
    transition: var(--default-transition);
  }
  main.home .catalog .row a.block a.go:hover {
    background-color: var(--secundary-white);
    color: var(--dark-color);
    border-color: transparent;
  }
  main.home .catalog .row a.block:hover .blurLayer {
    backdrop-filter: blur(5px);
  }
}
