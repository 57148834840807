main .crumbsRow {
  display: flex;
  width: 100%;
  margin-bottom: 4rem;
}
main .crumbsRow a {
  color: var(--secundary-white);
  font-size: 1.4rem;
}
main .crumbsRow .slash {
  margin: 0 1rem;
}
main .crumbsRow .current {
  color: var(--main-color);
}
main .container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
main .container .block {
  width: 32%;
  margin-bottom: 2rem;
  border-radius: 0.8rem;
  overflow: hidden;
  border: 0.1rem solid var(--border-color-default);
  margin-right: 1%;
}
main .container .block img {
  width: 100%;
  height: 45rem;
  object-fit: cover;
  object-position: center;
}
main .container .block .textPart {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  box-sizing: border-box;
}
main .container .block .textPart h3 {
  font: 2rem "Medium";
  text-align: center;
  color: var(--main-color);
  margin-bottom: 2rem;
  width: 100%;
  padding-bottom: 2rem;
  border-bottom: 0.1rem solid var(--border-color-default);
}
main .container .block .textPart h4 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}
main .container .block .textPart ul {
  list-style: disc;
  margin-left: 2rem;
}
main .container .block .textPart ul li {
  font-size: 1.4rem;
  line-height: 130%;
  margin-bottom: 1rem;
  color: var(--secundary-white);
}
main .container .block .textPart ul li::marker {
  color: var(--main-color);
}
main .container .block .textPart .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
}
main .container .block .textPart .row p {
  font-size: 1.6rem;
  color: var(--main-color);
}
main .container .block .textPart .row a {
  padding: 1rem 1.5rem;
  box-sizing: border-box;
  border: 0.1rem solid var(--main-color);
  color: var(--white-color);
  font-size: 1.6rem;
  border-radius: 10rem;
  transition: var(--default-transition);
}
main .container .block .textPart .row a:hover {
  background-color: var(--main-color);
  color: var(--white-color);
}
.pagination {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}
.pagination a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  color: var(--white-color);
  border-radius: 50%;
  font-size: 1.6rem;
  margin-right: 2rem;
  transition: var(--default-transition);
}
.pagination a.active {
  background-color: var(--main-color);
}
@media screen and (max-width: 768px) {
  main .crumbsRow {
    display: flex;
    width: 100%;
    margin-bottom: 4rem;
  }
  main .crumbsRow a {
    color: var(--secundary-white);
    font-size: 1.4rem;
  }
  main .crumbsRow .slash {
    margin: 0 1rem;
  }
  main .crumbsRow .current {
    color: var(--main-color);
  }
  main .container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
  }
  main .container .block {
    width: 100%;
    margin-bottom: 2rem;
    border-radius: 0.8rem;
    overflow: hidden;
    border: 0.1rem solid var(--border-color-default);
    margin-right: 1%;
  }
  main .container .block img {
    width: 100%;
    height: 40rem;
    object-fit: cover;
    object-position: bottom;
  }
  main .container .block .textPart {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    box-sizing: border-box;
  }
  main .container .block .textPart h3 {
    font: 2rem "Medium";
    text-align: center;
    color: var(--main-color);
    margin-bottom: 2rem;
    width: 100%;
    padding-bottom: 2rem;
    border-bottom: 0.1rem solid var(--border-color-default);
  }
  main .container .block .textPart h4 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }
  main .container .block .textPart ul {
    list-style: disc;
    margin-left: 2rem;
  }
  main .container .block .textPart ul li {
    font-size: 1.4rem;
    line-height: 130%;
    margin-bottom: 1rem;
    color: var(--secundary-white);
  }
  main .container .block .textPart ul li::marker {
    color: var(--main-color);
  }
  main .container .block .textPart .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
  }
  main .container .block .textPart .row p {
    font-size: 1.6rem;
    color: var(--main-color);
  }
  main .container .block .textPart .row a {
    padding: 1rem 1.5rem;
    box-sizing: border-box;
    border: 0.1rem solid var(--main-color);
    color: var(--white-color);
    font-size: 1.6rem;
    border-radius: 10rem;
    transition: var(--default-transition);
  }
  main .container .block .textPart .row a:hover {
    background-color: var(--main-color);
    color: var(--white-color);
  }
  .pagination {
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
  .pagination a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    color: var(--white-color);
    border-radius: 50%;
    font-size: 1.6rem;
    margin-right: 2rem;
    transition: var(--default-transition);
  }
  .pagination a.active {
    background-color: var(--main-color);
  }
}
