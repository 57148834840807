main.catalogPage .catalog {
  width: 90%;
  margin: 0 auto;
  margin-top: 6rem;
}
main.catalogPage .catalog h2 {
  font: 2.4rem "Medium";
  margin: 2rem auto;
}
main.catalogPage .catalog .row {
  display: flex;
  flex-wrap: wrap;
}
main.catalogPage .catalog .row a.block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  width: 32%;
  margin-right: 1.33%;
  margin-bottom: 1.33%;
  min-height: 35rem;
  border-radius: 1rem;
  overflow: hidden;
  position: relative;
}
main.catalogPage .catalog .row a.block img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}
main.catalogPage .catalog .row a.block .blurLayer {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: var(--default-transition);
}
main.catalogPage .catalog .row a.block p {
  position: relative;
  z-index: 3;
  margin: 2rem;
  color: var(--white-color);
  font-size: 2rem;
}
main.catalogPage .catalog .row a.block a.go {
  z-index: 3;
  margin: 2rem;
  color: var(--secundary-white);
  border: 0.1rem solid var(--secundary-white);
  font-size: 1.4rem;
  padding: 1rem;
  width: 40%;
  text-align: center;
  border-radius: 0.8rem;
  transition: var(--default-transition);
}
main.catalogPage .catalog .row a.block a.go:hover {
  background-color: var(--secundary-white);
  color: var(--dark-color);
  border-color: transparent;
}
main.catalogPage .catalog .row a.block:hover .blurLayer {
  backdrop-filter: blur(5px);
}
@media screen and (max-width: 768px) {
  main.catalogPage .catalog {
    width: 100%;
    margin: 0 auto;
    margin-top: 6rem;
  }
  main.catalogPage .catalog h2 {
    font: 2rem "Medium";
    margin: 2rem auto;
  }
  main.catalogPage .catalog .row {
    display: flex;
    flex-direction: column;
  }
  main.catalogPage .catalog .row a.block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    width: 100%;
    margin-right: 0;
    margin-bottom: 2rem;
    min-height: 35rem;
    border-radius: 1rem;
    overflow: hidden;
    position: relative;
  }
  main.catalogPage .catalog .row a.block img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
  main.catalogPage .catalog .row a.block .blurLayer {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    transition: var(--default-transition);
  }
  main.catalogPage .catalog .row a.block p {
    position: relative;
    z-index: 3;
    margin: 2rem;
    color: var(--white-color);
    font-size: 2rem;
  }
  main.catalogPage .catalog .row a.block a.go {
    z-index: 3;
    margin: 2rem;
    color: var(--secundary-white);
    border: 0.1rem solid var(--secundary-white);
    font-size: 1.4rem;
    padding: 1rem;
    width: 40%;
    text-align: center;
    border-radius: 0.8rem;
    transition: var(--default-transition);
  }
  main.catalogPage .catalog .row a.block a.go:hover {
    background-color: var(--secundary-white);
    color: var(--dark-color);
    border-color: transparent;
  }
  main.catalogPage .catalog .row a.block:hover .blurLayer {
    backdrop-filter: blur(5px);
  }
}
