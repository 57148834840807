main.articles h2 {
  font-size: 2.4rem;
  font-family: "Medium";
}
main.articles .containerArticles {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 5rem;
}
main.articles .containerArticles .articleBlock {
  display: flex;
  justify-content: space-between;
  width: 49%;
  height: 12rem;
  margin-bottom: 2%;
  border-radius: 1rem;
  overflow: hidden;
  border: 0.1rem solid var(--border-color-default);
}
main.articles .containerArticles .articleBlock .imgContainer {
  width: 30%;
  overflow: hidden;
}
main.articles .containerArticles .articleBlock .imgContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: var(--default-transition);
}
main.articles .containerArticles .articleBlock .textPart {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 65%;
  padding: 1.5rem;
  box-sizing: border-box;
}
main.articles .containerArticles .articleBlock .textPart h3 {
  font-size: 2rem;
  color: var(--white-color);
  font-family: "Medium";
}
main.articles .containerArticles .articleBlock .textPart a {
  font-size: 1.4rem;
  color: var(--secundary-white);
  border: 0.1rem solid;
  width: 40%;
  padding: 1rem;
  box-sizing: border-box;
  text-align: center;
  border-radius: 0.8rem;
  transition: var(--default-transition);
}
main.articles .containerArticles .articleBlock .textPart a:hover {
  background-color: var(--secundary-white);
  color: var(--dark-color);
}
main.articles .containerArticles .articleBlock:hover img {
  scale: 1.1;
}
@media screen and (max-width: 768px) {
  main.articles h2 {
    font-size: 2rem;
    font-family: "Medium";
  }
  main.articles .containerArticles {
    display: flex;
    flex-direction: column;
    justify-content: unset;
    flex-wrap: unset;
    margin-top: 3rem;
  }
  main.articles .containerArticles .articleBlock {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 2rem;
    border-radius: 1rem;
    overflow: hidden;
    border: 0.1rem solid var(--border-color-default);
  }
  main.articles .containerArticles .articleBlock .imgContainer {
    width: 100%;
    overflow: hidden;
  }
  main.articles .containerArticles .articleBlock .imgContainer img {
    width: 100%;
    height: 20rem;
    object-fit: cover;
    transition: var(--default-transition);
  }
  main.articles .containerArticles .articleBlock .textPart {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding: 1.5rem;
    box-sizing: border-box;
  }
  main.articles .containerArticles .articleBlock .textPart h3 {
    font-size: 1.8rem;
    color: var(--white-color);
    font-family: "Medium";
    line-height: 130%;
    margin-bottom: 2rem;
  }
  main.articles .containerArticles .articleBlock .textPart a {
    font-size: 1.4rem;
    color: var(--secundary-white);
    border: 0.1rem solid;
    width: 50%;
    padding: 1rem;
    box-sizing: border-box;
    text-align: center;
    border-radius: 0.8rem;
    transition: var(--default-transition);
  }
  main.articles .containerArticles .articleBlock .textPart a:hover {
    background-color: var(--secundary-white);
    color: var(--dark-color);
  }
  main.articles .containerArticles .articleBlock:hover img {
    scale: 1.1;
  }
}
