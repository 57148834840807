main.article h1 {
  font: 2.4rem "Medium";
}
main.article .articleContent {
  width: 60vw;
  margin: 0 auto;
}
main.article .articleContent .crumbsRow {
  display: flex;
  width: 100%;
  margin-bottom: 4rem;
}
main.article .articleContent .crumbsRow a {
  color: var(--secundary-white);
  font-size: 1.4rem;
}
main.article .articleContent .crumbsRow .slash {
  margin: 0 1rem;
}
main.article .articleContent .crumbsRow .current {
  color: var(--main-color);
}
main.article .articleContent img {
  border-radius: 1rem;
}
main.article .articleContent img.large {
  width: 100%;
  object-fit: contain;
  margin: 2rem 0;
}
main.article .articleContent .row-images {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
main.article .articleContent .row-images img {
  width: 32%;
  object-fit: contain;
  margin-bottom: 1rem;
}
main.article .articleContent .rowCenter {
  display: flex;
  width: 100%;
  justify-content: center;
}
main.article .articleContent .mediumImage {
  width: 40%;
  object-fit: contain;
  margin: 2rem 0;
}
main.article .articleContent p {
  font-size: 1.7rem;
  line-height: 130%;
  color: var(--secundary-white);
  margin-bottom: 2rem;
}
main.article .articleContent p a {
  color: var(--main-color);
}
main.article .articleContent h3 {
  font-size: 2rem;
  font-family: "Medium";
}
main.article .articleContent .textBlock {
  padding: 1rem;
  box-sizing: border-box;
  border: 0.1rem solid var(--main-color);
  border-radius: 0.8rem;
}
main.article .articleContent .textBlock a {
  color: var(--main-color);
}
main.article .articleContent .textBlock p {
  margin-bottom: 0;
}
main.article .articleContent ul {
  list-style: disc;
  margin: 2rem;
  margin-right: 0;
}
main.article .articleContent ul li {
  font-size: 1.7rem;
  color: var(--secundary-white);
  line-height: 130%;
  margin-bottom: 2rem;
}
main.article .articleContent ul li::marker {
  color: var(--main-color);
}
main.article .articleContent ul li a {
  color: var(--main-color);
}
main.article .articleContent ul li a.bold {
  color: var(--white-color);
}
main.article .articleContent .img2-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
main.article .articleContent .img2-container img {
  width: 48%;
}
@media screen and (max-width: 768px) {
  main.article h1 {
    font: 2.4rem "Medium";
  }
  main.article .articleContent {
    width: 100%;
    margin: 0 auto;
  }
  main.article .articleContent .crumbsRow {
    display: flex;
    width: 100%;
    margin-bottom: 4rem;
  }
  main.article .articleContent .crumbsRow a {
    color: var(--secundary-white);
    font-size: 1.4rem;
  }
  main.article .articleContent .crumbsRow .slash {
    margin: 0 1rem;
  }
  main.article .articleContent .crumbsRow .current {
    color: var(--main-color);
  }
  main.article .articleContent img {
    border-radius: 1rem;
  }
  main.article .articleContent img.large {
    width: 100%;
    object-fit: contain;
    margin: 2rem 0;
  }
  main.article .articleContent .row-images {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  main.article .articleContent .row-images img {
    width: 48%;
    object-fit: contain;
    margin-bottom: 1rem;
  }
  main.article .articleContent .rowCenter {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  main.article .articleContent .rowCenter img {
    width: 90%;
    object-fit: contain;
    margin: 2rem 0;
  }
  main.article .articleContent .mediumImage {
    width: 90%;
    object-fit: contain;
    margin: 2rem 0;
  }
  main.article .articleContent p {
    font-size: 1.7rem;
    line-height: 130%;
    color: var(--secundary-white);
    margin-bottom: 2rem;
  }
  main.article .articleContent p a {
    color: var(--main-color);
  }
  main.article .articleContent h3 {
    font-size: 2rem;
    line-height: 130%;
    font-family: "Medium";
  }
  main.article .articleContent .textBlock {
    padding: 1rem;
    box-sizing: border-box;
    border: 0.1rem solid var(--main-color);
    border-radius: 0.8rem;
  }
  main.article .articleContent .textBlock a {
    color: var(--main-color);
  }
  main.article .articleContent .textBlock p {
    margin-bottom: 0;
  }
  main.article .articleContent ul {
    list-style: disc;
    margin: 2rem;
    margin-right: 0;
  }
  main.article .articleContent ul li {
    font-size: 1.4rem;
    line-height: 130%;
    margin-bottom: 2rem;
  }
  main.article .articleContent ul li::marker {
    color: var(--main-color);
  }
  main.article .articleContent ul li a {
    color: var(--main-color);
  }
  main.article .articleContent ul li a.bold {
    color: var(--white-color);
  }
  main.article .articleContent .img2-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  main.article .articleContent .img2-container img {
    width: 48%;
  }
}
